<template>
  <section>
    <div class="body-1 mx-2 my-5 font-weight-bold">
      USER CATEGORIES
    </div>
    <v-row no-gutters>
      <v-col cols="12" xl="4">
        <v-card outlined max-width="300">
          <v-card-text>
            <v-form>
              <label class="caption">LEVEL NAME</label>
              <v-text-field solo hide-details 
              v-model="name"
              dense
              append-icon="mdi-upload-outline"
              @click:append="submit"/>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xl="5">
        <v-data-table
        v-model="selected"
        :headers="headers"
        :items="category"
        :items-per-page="5"
        show-select
        checkbox-color="primary"
        ></v-data-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data: () => ({
    name: '',
    selected: [],
    headers: [
      { text: "Level Name", value: "name" },
    ]
  }),
  computed: {
    ...mapState('admin', {
      category: (state) => state.user_category
    })
  },
  mounted() {
    this.getUserCategory()
  },
  methods: {
    ...mapActions('admin', [
      'storeUserCategory',
      'getUserCategory'
    ]),
    submit() {
      console.log(this.name);
      this.storeUserCategory({
        name: this.name
      }).then(() => {
        this.name = ''
      })
    }
  }

}
</script>